import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import tw from 'tailwind.macro'

import { Layout, PageHeader, News, Block } from '../components'
import { media } from '../styles/utils'
import {
    container,
    padding,
    hoverState,
    button,
    boxShadow,
} from '../styles/global'

const NewsSingle = props => {
    const { data } = props.pageContext
    // console.log(data)

    const breadcrumbs = {
        heading: {
            title: 'Strive Financial',
            link: '/'
        },
        description: {
            title: 'News',
            link: '/news/'
        },
        title: data.title,
    }

    const renderHeroImage = () => {
        return (
            <ImageWrapper>
                {data.hero_image && (
                    <Image
                        key={data.hero_image.id}
                        src={data.hero_image.sizes.large}
                    >
                        {(src, loading) => {
                            return (
                                <LoadedImage
                                    src={src}
                                    alt={data.hero_image?.alt}
                                    style={{ opacity: loading ? 0 : 1 }}
                                />
                            )
                        }}
                    </Image>
                )}
                {!data.hero_image && <PlaceholderImage />}
            </ImageWrapper>
        )
    }

    const renderBlocks = () => {
        if (!data.blocks) return

        return data.blocks.map((block, i) => {
            return (
                <Block
                    key={i}
                    index={i}
                    layout={block.acf_fc_layout}
                    {...block}
                />
            )
        })
    }

    const renderMain = () => {
        return (
            <Main>
                <Grid>
                    <Left>
                        {data.opening_paragraph && (
                            <Heading
                                dangerouslySetInnerHTML={{
                                    __html: data.opening_paragraph,
                                }}
                            />
                        )}
                        <Description>
                            {renderBlocks()}
                        </Description>
                    </Left>
                    <Right>
                        <Button to={`/news/`}>Back to News</Button>
                        <Info>{`Posted ${data.publishedDate}`}</Info>
                    </Right>
                </Grid>
            </Main>
        )
    }

    return (
        <Layout meta={data && data.seo}>
            {data && (
                <PageHeader
                    breadcrumbs={breadcrumbs}
                    page_heading={data.title ? data.title : undefined}
                    short_intro={
                        data.short_intro ? data.short_intro : undefined
                    }
                />
            )}
            <Wrapper>
                <Container>
                    {renderHeroImage()}
                    {renderMain()}
                </Container>
            </Wrapper>
            {data.news_posts && <News news_posts={data.news_posts} />}
        </Layout>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``
const Button = styled(Link)``

const Image = styled(ProgressiveImage)``
const LoadedImage = styled.img`
    transition: opacity 0.45s ease;
`

const Wrapper = styled.div`
    ${tw`bg-white`};
`

const Container = styled.div`
    ${container}
    ${padding}
`

const Grid = styled.div`
    ${tw`flex`};

    ${media.mob`
        ${tw`flex-col`};
    `}
`
const Left = styled.div``
const Right = styled.div``

// Hero Image

const PlaceholderImage = styled.div``
const ImageWrapper = styled.div`
    ${tw`relative z-10 w-full -mt-8 bg-white`};
    height: 0;
    padding-bottom: 56.25%;
    ${boxShadow};

    ${Image}, ${LoadedImage}, ${PlaceholderImage} {
        ${tw`absolute inset-0 w-full h-full object-cover p-4`};
    }
`

// Main

const BackLink = styled(Link)``
const Main = styled.div`
    ${tw`pt-16 pb-48`};

    ${media.mob`
        ${tw`pt-12 pb-32`};
    `}

    ${Left} {
        ${tw`w-2/3 pr-16 text-darkBlue`};

        ${media.tabPor`
            ${tw`pr-8`};
        `}

        ${media.mob`
            ${tw`w-full pr-0`};
        `}
    }

    ${Right} {
        ${tw`w-1/3 pl-16`};

        ${media.tabPor`
            ${tw`pl-8`};
        `}

        ${media.mob`
            ${tw`w-full mt-20 pl-0`};
        `}
    }

    ${Heading} {
        ${tw`mb-8`};
        font-weight: 500; /* medium */
        font-size: 1.1875rem;
        line-height: 1.27;
    }

    ${Description} {
        > *:not(:first-child) {
            ${tw`mt-6`};
        }
    }

    /* Right */

    ${Button} {
        ${button};
        ${tw`text-center py-3`};
        min-width: 220px;

        ${media.tabPor`
            min-width: 180px;
        `}
    }

    ${Info} {
        ${tw`relative mt-16 pb-4 leading-none text-orange`};
        font-weight: 600; /* semibold */
        font-size: 14px;

        ${media.mob`
            ${tw`mt-8`};
        `}

        &::after {
            content: '';
            ${tw`absolute bottom-0 left-0 w-8 bg-orange`};
            height: 1px;
        }
    }
`

export default NewsSingle
